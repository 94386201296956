<template>
  <div>
    <nav class="navbar">
      <div class="navbar-logo">
        <img src="@/assets/arbicoin-navbar-icon.png" alt="Arbicoin Logo" />
      </div>
      <a href="https://www.bitay.com/arbi-coin"><button class="navbar-btn">BUY TOKENS</button></a>
    </nav>
    <div class="slider">
      <div class="slides" :style="getStyle">
        <div
          class="slide"
          v-for="(slide, index) in slides"
          :key="index"
        >
          <div class="banner">
            <div class="banner-paragraph">
              <p class="slide-content" v-html="slide.content"></p>
            </div>
            <div class="banner-photo">
              <img :src="slide.image" alt="Slide image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indicator-dots">
      <span
        v-for="(slide, index) in slides"
        :key="index"
        :class="['dot', { active: currentSlide === index }]"
        @click="goToSlide(index)"
      >
        <img
          :src="
            currentSlide === index
              ? require('@/assets/active-dot.png')
              : require('@/assets/passive-dot.png')
          "
          alt="dot"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArbiCoinBanner",
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          content:
            'YOUR GATEWAY TO<br />PROFITABLE CRYPTO<br />VENTURES THROUGH<br /><span style="color:#3FFF00;">HIGH-SPEED ARBITRAGE</span><br />EXCELLENCE!',
          image: require("@/assets/arbicoin-banner-icon-1.png"),
        },
        {
          content:
            '<span style="color:#3FFF00;">ARBICOIN (ABX)</span><br/>WHERE INNOVATION<br />MEETS PROFIT: <span style="color:#3FFF00;">SEIZE<br />OPPORTUNITIES WITH <br />HIGH-SPEED ARBITRAGE!</span>',
          image: require("@/assets/arbicoin-banner-icon-2.png"),
        },
      ],
    };
  },
  computed: {
    getStyle() {
      return {
        transform: `translateX(-${this.currentSlide * 100}%)`,
        interval: null,
      };
    },
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },
  },
  mounted() {
    setInterval(() => {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    }, 8000);
  }
};
</script>

<style>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #212121;
  padding: 40px 140px 40px 140px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}
.navbar-logo img {
  height: 38px;
}

.navbar-btn {
  height: 52px;
  width: 215px;
  background-color: #3fff00;
  color: #212121;
  font-size: 24px;
  font-family: "OpenSans", sans-serif;
  font-weight: bold;
  border: none;
  padding-top: 9px 20px;
  border-radius: 0.3em;
  cursor: pointer;
  transition: background-color 0.3s;
}
.banner {
  display: flex;
  background-color: #212121;
  text-align: start;
  justify-content: space-between;
  align-items: center;
}
.banner-paragraph {
  color: #fff;
  font-size: 42px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  padding: 40px 140px;
  padding-right: 0;
  white-space: pre-line;
  line-height: 75px;
  margin-top: -20px;
}

.slider.slides.slide.banner.banner-paragraph span {
  color: #3fff00 !important;
}
.slider {
  position: relative;
  height: 500px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  transition: all 0.5s ease;
}
.indicator-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
  background-color: #212121;
  width: 40px;
  justify-content: space-between;
  margin-top: -10px;
}
@media (max-width: 768px) {
  .navbar {
    padding: 20px;
  }
  .navbar-logo img {
    height: 25px;
  }
  .navbar-btn {
    height: 36px;
    width: 136px;
    font-size: 14px;
  }
  .banner {
    flex-direction: column;
  }
  .banner-paragraph {
    font-size: 24px;
    line-height: 40px;
    padding: 20px;
    text-align: start;
    padding-left: 0;
  }
  .banner-photo img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin-left: 20px;
    margin-bottom: -25px;
    margin-top: -60px;
  }
}

/* @media (min-width: 769px) and (max-width: 1024px) {
  .navbar {
    padding: 30px;
  }
  .navbar-logo img {
    height: auto;
  }
  .navbar-btn {
    height: 45px;
    width: 180px;
    font-size: 20px;
  }
  .banner {
    flex-direction: row;
    padding: 30px;
  }
  .banner-paragraph {
    font-size: 32px;
    line-height: 1.5;
    padding: 30px;
  }
  .banner-photo img {
    max-width: 50%;
    height: auto;
  }
  .banner-paragraph {
    font-size: 24px;
  }
} */
@media (min-width: 1024px) and (max-width: 1550px) {
  .banner-photo img{
    width: auto;
    height: 500px;
  }
  .proof-of-concept {
    padding: 0;
    background-color: #fff;
    margin-top: 82px;
  }
}
</style>
