<template>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="96" viewBox="0 0 32 96">
  <g id="Group_26803" data-name="Group 26803" transform="translate(-881 -290)">
    <path id="Line_2" data-name="Line 2" d="M1.5,64h-3V0h3Z" transform="translate(897.5 290)" fill="#3fff00"/>
    <path id="Ellipse_225" data-name="Ellipse 225" d="M16,3A13,13,0,0,0,6.808,25.192,13,13,0,0,0,25.192,6.808,12.915,12.915,0,0,0,16,3m0-3A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" transform="translate(881 354)" fill="#3fff00"/>
  </g>
</svg>
</template>
<script>
export default {
  name: 'RoadmapIcon'
}
</script>