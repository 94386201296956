<template>
  <div class="container">
    <div class="buy-abx">
      <div class="buy-abx-left">
        <div class="buy-abx-slogan">
          <h1 v-if="isWidthLessThan768">
            WHY ARE YOU WAITING<br />WHILETHE WHOLE WORLD<br />IS MAKING
            PROFITS<br />THROUGH ARBITRAGE?
          </h1>
          <h1 v-else>
            WHY ARE YOU WAITING WHILE<br />THE WHOLE WORLD IS MAKING PROFITS
            THROUGH ARBITRAGE?
          </h1>
        </div>
        <div class="buy-abx-whitepaper">
          <h2>ArbiCoin (ABX) Whitepaper</h2>
          <div class="buy-abx-whitepaper-center">
            <div class="whitepaper-paragraph">
              <p>
                You can download the ABX Whitepaper document to get detailed
                information about <br />ArbiCoin “ABX” and learn much more.
              </p>
              <div class="whitepaper-paragraph-button">TAKE A LOOK</div>
            </div>
            <div class="whitepaper-img">
              <img src="@/assets/litepaper.png" alt="liteper" />
            </div>
          </div>
        </div>
      </div>
      <div class="buy-abx-right">
        <div class="buy-abx-info-top">
          <div class="buy-abx-info-left">
            <h3>Current Stage</h3>
            <h2>Stage 1</h2>
          </div>
          <div class="buy-abx-info-right">
            <h3>Total Raised</h3>
            <h2>$8,156,393.38</h2>
          </div>
        </div>
        <div class="buy-abx-sold-bar">
          <div class="buy-abx-sold-bar-top">
            <div class="buy-abx-sold-bar-left">
              <img
                class="buy-abx-sold-bar-img"
                src="@/assets/buy-abx-double-arrow.png"
                alt=""
              />
              <span class="buy-abx-sold-bar-percentage highligth"
                >83.72% Sold</span
              >
            </div>
            <div class="buy-abx-sold-bar-right">
              <span class="buy-abx-sold-bar-remain">109,556,526</span>
              <span class="buy-abx-sold-bar-remaining highligth"
                >Remaining</span
              >
            </div>
          </div>
          <div class="progress-container">
            <div
              class="buy-abx-sold-bar-colored-bar"
              :style="progressBarStyle"
            ></div>
          </div>
        </div>
        <div class="buy-abx-numbers">
          <div class="buy-abx-num">
            <span class="highligth">0.0224 USDT</span> = XXX ABX
          </div>
          <div class="buy-abx-next-stage">Next Stage: 0.0255</div>
        </div>
        <div class="buy-abx-solded highligth">715,787,788 ABX Sold</div>
        <div class="buy-abx-payment-icons">
          <img src="@/assets/payment-options.png" alt="payment options" />
        </div>
        <div class="buy-abx-button">
          <a href="https://www.bitay.com/arbi-coin">BUY ABX</a>
        </div>
      </div>
    </div>
    <div class="how-does-work">
      <h1>HOW DOES <span>ARBICOIN (ABX)</span> WORK?</h1>
      <p>
        The crypto market, characterized by its decentralized nature and varying
        liquidity across exchanges, provides a fertile ground for a project like
        ArbiCoin. ABX is conceived as a speculative coin designed to capitalize
        on the inherent inefficiencies and price divergences that exist among
        crypto exchanges. <br /><br />ArbiCoin aims to bridge the gap between
        two well-established trading strategies, arbitrage, and HFT, creating a
        synergistic approach that maximizes profit potential for its holders.
        Through strategic server placement, advanced algorithms, and a robust
        liquidity engine, ArbiCoin endeavors to redefine speculative trading in
        the crypto realm.
      </p>
    </div>
    <div class="meet-arbitrage">
      <h1>MEET <span>ARBITRAGE</span></h1>
      <p v-if="isWidthLessThan768">
        Arbitrage is the process of simultaneous buying and selling of an asset
        from different platforms, exchanges or locations to cash in on the price
        difference (usually small in percentage terms). While getting into an
        arbitrage trade, the quantity of the underlying asset bought and sold
        should be the same. Only the price difference is captured as the net
        pay-off from the trade.<br /><a
          href="https://economictimes.indiatimes.com/definition/arbitrage"
          >(Economic Times, 2018 - https://economictimes.indiatimes.com<br />/definition/arbitrage)</a
        >
        <br /><br />For example, imagine a large multinational company lists its
        stock on the New York Stock Exchange (NYSE) and London Stock Exchange.
        On the NYSE, it’s priced at $1.05, and on the London Stock Exchange,
        it’s $1.10. If an investor were to buy it for $1.05 and sell it for
        $1.10, they’d make a small profit of five cents per share.
      </p>
      <p v-else>
        Arbitrage is the process of simultaneous buying and selling of an asset
        from different platforms, exchanges or locations to cash in on the price
        difference (usually small in percentage terms). While getting into an
        arbitrage trade, the quantity of the underlying asset bought and sold
        should be the same. Only the price difference is captured as the net
        pay-off from the trade.<br /><a
          href="https://economictimes.indiatimes.com/definition/arbitrage"
          >(Economic Times, 2018 -
          https://economictimes.indiatimes.com/definition/arbitrage)</a
        >
        <br /><br />For example, imagine a large multinational company lists its
        stock on the New York Stock Exchange (NYSE) and London Stock Exchange.
        On the NYSE, it’s priced at $1.05, and on the London Stock Exchange,
        it’s $1.10. If an investor were to buy it for $1.05 and sell it for
        $1.10, they’d make a small profit of five cents per share.
      </p>
      <h1>THE POWER OF <span>HIGH-FREQUENCY TRADING</span></h1>
      <p>
        HFT is a subset of algorithmic trading and, in turn, HFT includes Ultra
        HFT trading. Algorithms essentially work as middlemen between buyers and
        sellers, with HFT and Ultra HFT being a way for traders to capitalize on
        infinitesimal price discrepancies that might exist only for a minuscule
        period.<br />Computer-assisted rule-based algorithmic trading uses
        dedicated programs that make automated trading decisions to place
        orders. AT splits large-sized orders and places these split orders at
        different times and even manages trade orders after their submission.
      </p>
      <h1>UNLEASHING <span>THE POWER OF SPEED</span></h1>
      <p>
        High-frequency trading operates on the principle that executing a large
        number of orders at exceptionally high speeds can capitalize on even the
        smallest price discrepancies. ArbiCoin embraces this concept, leveraging
        advanced algorithms to enable swift and precise trade execution.
      </p>
    </div>
    <div class="synergy-of-arbitrage-hft">
      <div class="synergy-of-arbitrage-hft-header">
        <h1>SYNERGY OF ARBITRAGE AND<br />HIGH-FREQUENCY TRADING</h1>
      </div>
      <div class="outer">
        <div class="row">
          <div class="six-box-1 grid-box">
            <h1>MAXIMIZING PROFIT POTENTIAL</h1>
            <p>
              ArbiCoin's innovation lies in seamlessly<br />blending arbitrage
              and HFT. By identifying and<br />exploiting price disparities
              across exchanges<br />at unprecedented speeds, ArbiCoin
              provides<br />its holders with a unique advantage in the<br />speculative
              crypto market.
            </p>
          </div>
          <div class="six-box-2 grid-box">
            <h1>SWIFT CAPITALIZATION ON MARKET INEFFICIENCIES</h1>
            <p>
              Combining arbitrage and HFT ensures rapid<br />identification and
              exploitation of even minor<br />price differences, maximizing
              profit potential.
            </p>
          </div>
          <div class="six-box-3 grid-box">
            <h1>LIGHTNING-FAST TRADE EXECUTION</h1>
            <p>
              Leveraging HFT principles, ArbiCoin executes<br />trades within
              milliseconds, ensuring quick and<br />precise actions in response
              to dynamic<br />market conditions.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="six-box-4 grid-box">
          <h1>ADAPTIVE TO CHANGING MARKETS</h1>
          <p>
            ArbiCoin adapts to real-time market data,<br />navigating through
            fluctuations with agility, a<br />crucial aspect derived from the
            adaptive<br />nature of arbitrage and HFT.
          </p>
        </div>
        <div class="six-box-5 grid-box">
          <h1>STRATEGIC SERVER PLACEMENT FOR MINIMAL LATENCY</h1>
          <p>
            Positioned strategically between exchanges,<br />ArbiCoin minimizes
            latency, enabling faster<br />data transmission and optimizing
            trade<br />
            execution efficiency.
          </p>
        </div>
        <div class="six-box-6 grid-box">
          <h1>CONSISTENT PROFITABILITY WITH MARGINAL PRICE DIFFERENCES</h1>
          <p>
            The synergy enhances profit margins by<br />efficiently capitalizing
            on slight price<br />disparities across multiple exchanges.
          </p>
        </div>
      </div>
    </div>
    <div class="big-box grid-box">
      <h1>FACILITATING SPECULATION IN<br />AN EXPANDING MARKET</h1>
      <p>
        As ArbiCoin scales to handle higher volumes and varied market dynamics,
        it creates opportunities for<br />speculative trading, enhancing its
        attractiveness to investors.<br />In essence, ArbiCoin's synergy of
        arbitrage and HFT forms a potent combination, redefining speculative<br />
        trading by capitalizing on speed, precision, and adaptability in the
        ever-evolving cryptocurrency market.
      </p>
    </div>
    <div class="partners-container">
      <h1>OUR PARTNERS</h1>
      <div class="marquee">
        <div class="fade left-fade"></div>
        <div class="white-line top-line"></div>
        <div class="marquee-content">
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
          <div class="partner-logo">
            <img src="@/assets/partners-logo.png" alt="Binance" />
          </div>
        </div>
        <div class="white-line bottom-line"></div>
        <div class="fade right-fade"></div>
      </div>
    </div>
    <div class="roadmap">
      <h1>ROADMAP</h1>
      <div
        class="roadmap-container"
        @mousedown="startDrag"
        @mousemove="onDrag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
        ref="roadmap"
      >
        <div class="roadmap-box">
          <h2>2024 QUARTER 1</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <img src="@/assets/right-arrow.png" v-if="mobilImg" class="mobilImg"/>
            <h3>Launch and Initial Development</h3>
            <span>
              <h4>Official Project Launch and Private Sale</h4>
              <br />
              <p>
                ArbiCoin goes live and becomes tradable on the market<br />with
                the arbitrage engine operational.<br />Commence a private sale
                with discounted prices and a<br />lock-up period.<br /><br />
              </p>
              <h4>Public Token Sale</h4>
              <br />
              <h4>Whitepaper Release and Updates</h4>
              <br />
              <h4>Technical Development and Optimization</h4>
              <br />
              <h4>Launch</h4>
              <br />
              <p>
                Roll out the beta version of ArbiCoin, incorporating user<br />feedback
                for further optimization.<br />Profit Distribution
                Implementation, Global Exchange<br />Listing, Global Marketing
                Campaign, Community<br />Engagement Programs, Strategic
                Partnerships.
              </p>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2024 QUARTER 2</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Expansion and Scaling</h3>
            <span>
              <h4>Expansion & Scaling</h4>
              <br />
              <p>
                Expansion of Arbitrage Pairs, Optimization and User<br />Interface
                Enhancements, Further Expansion Exploration,<br />Advanced
                Arbitrage Strategies, Diversification of<br />Services,
                Continuous Ecosystem Growth
              </p>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2024 QUARTER 3</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Continuous Improvement and Planning<br />Ahead</h3>
            <span>
              <h4>Continued Optimization</h4>
              <br />
              <p>
                Continue refining the platform based on user experience<br />and
                market dynamics.
              </p>
              <h4>Security Audits</h4>
              <br />
              <h4>Roadmap for Future Development</h4>
              <br />
              <h4>Introduction of Governance Mechanisms</h4>
              <br />
              <h4>Decentralized Arbitrage Nodes</h4>
              <br />
              <h4>Strategic Acquisitions</h4>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2024 QUARTER 4</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Sustainable Ecosystem and Future<br />Developments</h3>
            <span>
              <h4>Sustainable Community Growth</h4>
              <br />
              <p v-if="isWidthLessThan768">
                Evaluating Emerging Technologies,<br />Year-End Review and
                Planning
              </p>
              <p v-else>
                Evaluating Emerging Technologies,Year-End Review and<br />Planning
              </p>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2025 QUARTER 1 & 2</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Advanced Technological Integration</h3>
            <span>
              <h4>Quantum Computing Research</h4>
              <br />
              <p>
                Begin research into quantum computing applications for<br />enhanced
                security and algorithmic efficiency.
              </p>
              <br /><br />
              <h4>Cross-Platform Integration</h4>
              <br />
              <p>
                Work towards seamless integration with<br />emerging blockchain
                platforms.
              </p>
              <br /><br />
              <h4>Advanced AI and Predictive Analytics</h4>
              <br />
              <p>
                Develop advanced AI models for predictive analytics in<br />arbitrage.
              </p>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2025 QUARTER 3 & 4</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Global Adoption and Partnerships</h3>
            <span>
              <h4>Strategic Partnerships with Tech Giants</h4>
              <br />
              <p>
                Seek partnerships with major technology companies for<br />
                collaborative ventures.
              </p>
              <br />
              <h4>Global Adoption Initiatives</h4>
              <br />
              <p>
                Launch targeted adoption campaigns in regions with<br />emerging
                cryptocurrency markets.
              </p>
              <br />
              <h4>Incorporate IoT and Smart Contracts</h4>
              <br />
              <p>
                Investigate the integration of Internet of Things (IoT) data<br />
                into arbitrage strategies.
              </p>
            </span>
          </div>
        </div>
        <div class="roadmap-box">
          <h2>2026 AND BEYOND</h2>
          <div class="roadmap-item">
            <RoadmapIcon class="roadmap-icon" />
            <h3>Ecosystem Interconnectivity</h3>
            <span>
              <h4>Cross-Industry Collaborations</h4>
              <br />
              <h4>Integration with Traditional Finance</h4>
              <br />
              <h4>Blockchain Governance Standards</h4>
              <br />
              <p>
                Contribute to the development of industry-wide<br />blockchain
                governance standards. Participate in forums<br />and initiatives
                that shape the regulatory landscape.
              </p>
              <br /><br />
              <h4>Autonomous Ecosystem and Global Impact</h4>
              <br />
              <p>
                Introduce features that enable autonomous decision-<br />making
                within the ArbiCoin ecosystem. Implement<br />decentralized
                governance fully controlled by the<br />community.
              </p>
              <br /><br />
              <h4>Global Impact and Philanthropy</h4>
              <br />
              <p>
                Establish a foundation for social impact and philanthropy<br />using
                blockchain.
              </p>
              <br /><br />
              <h4>Research Emerging Technologies</h4>
              <br />
              <p>
                Dedicate resources to researching emerging<br />technologies
                outside the blockchain space.<br />Explore the integration of
                artificial intelligence, virtual<br />reality, or augmented
                reality.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="us-in-media">
      <h1>US IN THE MEDIA</h1>
      <div></div>
    </div> -->
  </div>
  <div class="world-bg">
    <img src="@/assets/world-bg.png" alt="world background image" />
  </div>
  <div class="proof-of-concept">
    <div class="proof-of-concept-items">
      <h1><span>Arbicoin (ABX): </span>Proof Of Concept</h1>
      <div class="proof-of-concept-item">
        <div>
          <h2>INITIAL ARBITRAGE SETUP</h2>
          <p>
            Target specific cryptocurrency pairs on two brokerages. Develop and
            implement algorithms for detecting arbitrage opportunities.
            Simulate<br />
            trades to verify the efficiency of the arbitrage engine.
          </p>
        </div>
        <hr />
        <div>
          <h2>SCALING ARBITRAGE STRATEGIES</h2>
          <p>
            Add more cryptocurrency pairs to the arbitrage portfolio. Integrate
            additional brokerages for increased complexity. Assess the
            arbitrage<br />
            engine's ability to handle multiple arbitrage opportunities.
          </p>
        </div>
        <hr />
        <div>
          <h2>VOLATILITY AND SPECULATION</h2>
          <p>
            Simulate higher frequency trades and introduce random market
            fluctuations via support of artificial intelligence models. Evaluate
            the<br />arbitrage engine's responsiveness to changing market
            conditions.
          </p>
        </div>
        <hr />
        <div>
          <h2>VOLUME SIMULATION</h2>
          <p>
            Gradually increase trading volume to assess scalability. Monitor
            latency and speed of trade execution under growing transaction
            loads.
          </p>
        </div>
        <div>
          <hr />
          <h2>REAL-TIME SIMULATION WITH MULTIPLE BROKERAGES</h2>
          <p>
            Implement live market data feeds for real-world simulation. Execute
            diverse arbitrage and speculative trades. Analyze outcomes,
            including<br />
            profit margins, trade execution speeds, and overall efficiency.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="faq">
    <h1>FREQUENTLY ASKED QUESTIONS</h1>
    <div
      class="faq-item"
      :class="{ open: item.isOpen }"
      v-for="(item, index) in faqItems"
      :key="index"
    >
      <h2 @click="toggleDropdown(index)">
        <span v-html="item.title"></span>
        <img
          src="@/assets/down-arrow.png"
          class="arrow"
          :class="{ 'display-arrow': item.isOpen }"
        />
        <img
          src="@/assets/up-arrow.png"
          class="arrow"
          :class="{ 'display-arrow': !item.isOpen }"
        />
      </h2>
      <p v-show="item.isOpen" v-html="item.content" class="faq-content"></p>
    </div>
  </div>
  <div class="footer"></div>
</template>

<script>
import RoadmapIcon from "./components/RoadmapIcon.vue";

export default {
  name: "ArbiCoinBuy",
  components: {
    RoadmapIcon,
  },
  data() {
    return {
      percentage: 83,
      windowWidth: window.innerWidth,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      isOpen: false,
      mobilImg: false,
      faqItems: [
        {
          title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR.",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae ante dapibus, eleifend massa quis, posuere odio. In dapibus non dolor sit amet sagittis. Praesent eget pellentesque sem. Suspendisse sit amet hendrerit turpis. Suspendisse justo neque, aliquam consequat neque semper, pharetra porta arcu. Suspendisse ac risus augue. Mauris laoreet elementum maximus. Duis pharetra, dui vel volutpat faucibus, augue nulla finibus nisi, at laoreet mi felis ac nulla. Donec gravida arcu mauris, nec rhoncus odio blandit in. Donec tempor justo eu lectus volutpat fringilla. Mauris vestibulum malesuada vulputate.<br /><br />Phasellus sit amet est urna. Proin iaculis neque ut ex congue, in feugiat velit vestibulum. Phasellus malesuada suscipit urna sed rutrum. Nunc gravida justo vitae purus vestibulum blandit. Morbi vel euismod turpis. Aenean a ornare sem. Aliquam id turpis consequat, laoreet nunc et, pretium quam. Integer laoreet sed ante non pretium. Nulla facilisi. Vivamus eu imperdiet elit. Curabitur tincidunt lacus neque, ut eleifend purus gravida in. Ut dignissim convallis tempus.",
          isOpen: false,
        },
        {
          title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR.",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae ante dapibus, eleifend massa quis, posuere odio. In dapibus non dolor sit amet sagittis. Praesent eget pellentesque sem. Suspendisse sit amet hendrerit turpis. Suspendisse justo neque, aliquam consequat neque semper, pharetra porta arcu. Suspendisse ac risus augue. Mauris laoreet elementum maximus. Duis pharetra, dui vel volutpat faucibus, augue nulla finibus nisi, at laoreet mi felis ac nulla. Donec gravida arcu mauris, nec rhoncus odio blandit in. Donec tempor justo eu lectus volutpat fringilla. Mauris vestibulum malesuada vulputate.<br /><br />Phasellus sit amet est urna. Proin iaculis neque ut ex congue, in feugiat velit vestibulum. Phasellus malesuada suscipit urna sed rutrum. Nunc gravida justo vitae purus vestibulum blandit. Morbi vel euismod turpis. Aenean a ornare sem. Aliquam id turpis consequat, laoreet nunc et, pretium quam. Integer laoreet sed ante non pretium. Nulla facilisi. Vivamus eu imperdiet elit. Curabitur tincidunt lacus neque, ut eleifend purus gravida in. Ut dignissim convallis tempus.",
          isOpen: false,
        },
        {
          title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR.",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae ante dapibus, eleifend massa quis, posuere odio. In dapibus non dolor sit amet sagittis. Praesent eget pellentesque sem. Suspendisse sit amet hendrerit turpis. Suspendisse justo neque, aliquam consequat neque semper, pharetra porta arcu. Suspendisse ac risus augue. Mauris laoreet elementum maximus. Duis pharetra, dui vel volutpat faucibus, augue nulla finibus nisi, at laoreet mi felis ac nulla. Donec gravida arcu mauris, nec rhoncus odio blandit in. Donec tempor justo eu lectus volutpat fringilla. Mauris vestibulum malesuada vulputate.<br /><br />Phasellus sit amet est urna. Proin iaculis neque ut ex congue, in feugiat velit vestibulum. Phasellus malesuada suscipit urna sed rutrum. Nunc gravida justo vitae purus vestibulum blandit. Morbi vel euismod turpis. Aenean a ornare sem. Aliquam id turpis consequat, laoreet nunc et, pretium quam. Integer laoreet sed ante non pretium. Nulla facilisi. Vivamus eu imperdiet elit. Curabitur tincidunt lacus neque, ut eleifend purus gravida in. Ut dignissim convallis tempus.",
          isOpen: false,
        },
        {
          title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR.",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae ante dapibus, eleifend massa quis, posuere odio. In dapibus non dolor sit amet sagittis. Praesent eget pellentesque sem. Suspendisse sit amet hendrerit turpis. Suspendisse justo neque, aliquam consequat neque semper, pharetra porta arcu. Suspendisse ac risus augue. Mauris laoreet elementum maximus. Duis pharetra, dui vel volutpat faucibus, augue nulla finibus nisi, at laoreet mi felis ac nulla. Donec gravida arcu mauris, nec rhoncus odio blandit in. Donec tempor justo eu lectus volutpat fringilla. Mauris vestibulum malesuada vulputate.<br /><br />Phasellus sit amet est urna. Proin iaculis neque ut ex congue, in feugiat velit vestibulum. Phasellus malesuada suscipit urna sed rutrum. Nunc gravida justo vitae purus vestibulum blandit. Morbi vel euismod turpis. Aenean a ornare sem. Aliquam id turpis consequat, laoreet nunc et, pretium quam. Integer laoreet sed ante non pretium. Nulla facilisi. Vivamus eu imperdiet elit. Curabitur tincidunt lacus neque, ut eleifend purus gravida in. Ut dignissim convallis tempus.",
          isOpen: false,
        },
        {
          title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR.",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae ante dapibus, eleifend massa quis, posuere odio. In dapibus non dolor sit amet sagittis. Praesent eget pellentesque sem. Suspendisse sit amet hendrerit turpis. Suspendisse justo neque, aliquam consequat neque semper, pharetra porta arcu. Suspendisse ac risus augue. Mauris laoreet elementum maximus. Duis pharetra, dui vel volutpat faucibus, augue nulla finibus nisi, at laoreet mi felis ac nulla. Donec gravida arcu mauris, nec rhoncus odio blandit in. Donec tempor justo eu lectus volutpat fringilla. Mauris vestibulum malesuada vulputate.<br /><br />Phasellus sit amet est urna. Proin iaculis neque ut ex congue, in feugiat velit vestibulum. Phasellus malesuada suscipit urna sed rutrum. Nunc gravida justo vitae purus vestibulum blandit. Morbi vel euismod turpis. Aenean a ornare sem. Aliquam id turpis consequat, laoreet nunc et, pretium quam. Integer laoreet sed ante non pretium. Nulla facilisi. Vivamus eu imperdiet elit. Curabitur tincidunt lacus neque, ut eleifend purus gravida in. Ut dignissim convallis tempus.",
          isOpen: false,
        },
      ],
    };
  },
  mounted () {
    window.addEventListener('resize', this.cihazKontrol);
    // Sayfa yüklendiğinde de kontrol etmek için
    this.cihazKontrol();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.cihazKontrol);
  },
  computed: {
    isWidthLessThan768() {
      return this.windowWidth < 768;
    },
    progressBarStyle() {
      return {
        width: `${this.percentage}%`,
        backgroundImage: `linear-gradient(to right, #9522E5, #FF4026)`,
        backgroundColor: this.percentage === 100 ? "transparent" : "#FF4026",
      };
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    cihazKontrol() {
      this.mobilImg = window.innerWidth < 768;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.clientX;
      this.scrollLeft = this.$refs.roadmap.scrollLeft;
      this.$refs.roadmap.classList.add("grabbing");
    },
    onDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.clientX;
      const walk = (this.startX - x) * 3;
      this.$refs.roadmap.scrollLeft = this.scrollLeft + walk;
    },
    endDrag() {
      this.isDragging = false;
      this.$refs.roadmap.classList.remove("grabbing");
    },
    toggleDropdown(clickedIndex) {
      this.faqItems.forEach((item, index) => {
        if (index === clickedIndex) {
          item.isOpen = !item.isOpen;
        } else {
          item.isOpen = false;
        }
      });
    },
  },
};
</script>

<style>
.mobilImg{
  width: 60px;
  position: absolute;
  right: 10px;
  top: 10px;
}
html,
body {
  margin: 0;
  overflow-x: hidden;
  background-color: #212121;
  font-family: "Poppins", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.roadmap-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.roadmap {
  overflow-y: hidden;
}
body a {
  text-decoration: none;
  color: #fff;
}
* {
  box-sizing: border-box;
}
.container {
  background-image: url("@/assets/abx-bg-img.png");
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.buy-abx {
  flex-direction: row;
  display: flex;
  padding: 40px 140px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.buy-abx-left,
.buy-abx-right {
  flex-grow: 1;
}
.buy-abx-right {
  background-color: #1216b2;
  border-radius: 16px;
}
.buy-abx-left {
  margin-right: 48px;
}
.buy-abx-slogan {
  width: 471px;
  font-size: 15px;
}
.buy-abx-whitepaper {
  display: flex;
  background-color: #1a97ff;
  border-radius: 16px;
  margin-right: 0;
  padding: 21px 32px 32px 32px;
  flex-direction: column;
}
.buy-abx-whitepaper h2 {
  font-size: 28px;
  width: 390px;
}
.whitepaper-paragraph p {
  font-size: 14px;
  width: 226px;
  line-height: 25px;
  font-weight: 100;
}
.whitepaper-paragraph-button {
  height: 42px;
  width: 154px;
  border-radius: 8px;
  background-color: #1216b2;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whitepaper-img {
  display: flex;
  width: 144px;
  height: 167px;
}
.buy-abx-whitepaper-center {
  display: flex;
}
.buy-abx-right {
  padding: 32px;
}
.buy-abx-info-top {
  display: flex;
  justify-content: space-between;
}
.buy-abx-info-top h2 {
  font-size: 24px;
  color: #3fff00;
  font-weight: 400;
}
.buy-abx-info-top h3 {
  font-size: 20px;
  font-weight: 100;
}
.buy-abx-sold-bar-top {
  margin-top: 38px;
  display: flex;
  height: 74px;
  border-radius: 8px 8px 0px 0px;
  background-color: #212121;
  justify-content: space-between;
  align-items: center;
  padding-left: 26px;
  padding-right: 26px;
}
.buy-abx-slogan h1 {
  width: 494px;
  line-height: 45px;
}
.buy-abx-sold-bar-right {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.buy-abx-sold-bar-remain {
  display: flex;
  flex-direction: row;
}
.buy-abx-sold-bar-remaining {
  display: flex;
  flex-direction: row;
}
.highligth {
  color: #3fff00;
}
.buy-abx-sold-bar-img {
  margin-right: 10px;
  font-size: 20px;
}
.buy-abx-sold-bar-percentage {
  font-size: 20px;
}
.buy-abx-numbers {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 42px;
  display: flex;
  justify-content: space-between;
}
.buy-abx-info-right h3 {
  margin-left: 36px;
}
.buy-abx-solded {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-bottom: 32px;
}
.buy-abx-payment-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.buy-abx-button {
  height: 68px;
  border-radius: 8px;
  background-color: #3fff00;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
.buy-abx-button a {
  color: #000;
}
.progress-container {
  width: 100%;
  background-color: gray;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.buy-abx-sold-bar-colored-bar {
  height: 20px;
  border-radius: 0 0 8px 8px;
}
.how-does-work {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 140px;
  margin-bottom: 63px;
}
.how-does-work h1 {
  font-size: 32px;
  text-align: center;
}
.how-does-work p {
  font-size: 18px;
  font-weight: 100;
  max-width: 1086px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-does-work span {
  color: #3fff00;
}
.meet-arbitrage {
  padding: 40px 140px;
  width: 1086px;
  background-color: rgba(37, 127, 0, 0.2);
  border-radius: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.meet-arbitrage h1 {
  text-align: center;
  margin-bottom: 32px;
  font-size: 32px;
}
.meet-arbitrage p {
  font-weight: 100;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 48px;
}
.meet-arbitrage span {
  color: #3fff00;
}
.synergy-of-arbitrage-hft {
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
}
.synergy-of-arbitrage-hft-header {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  line-height: 80px;
  font-size: 25px;
}
.grid-box {
  width: 342px;
  height: 194px;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
  margin-bottom: 30px;
}
.grid-box h1 {
  font-size: 18px;
  text-align: center;
}
.grid-box p {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 100;
}
.six-box {
  flex: 1;
}
.six-box-1 {
  background-image: url("@/assets/six-box-bg-1.png");
  background-repeat: round;
  background-color: #1216b2;
}
.six-box-1 p {
  margin-top: 0px;
}
.six-box-2 {
  background-image: url("@/assets/six-box-bg-2.png");
  background-repeat: round;
  background-color: #AE2B28;
}
.six-box-3 {
  background-image: url("@/assets/six-box-bg-3.png");
  background-repeat: round;
  background-color: #8E2CFF;
}
.six-box-4 {
  background-image: url("@/assets/six-box-bg-4.png");
  background-repeat: round;
  background-color: #168364;
}
.six-box-5 {
  background-image: url("@/assets/six-box-bg-5.png");
  background-repeat: round;
  background-color: #1a97ff;
}
.six-box-6 {
  background-image: url("@/assets/six-box-bg-6.png");
  background-repeat: round;
  background-color: #1D1D1D;
}
.big-box {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 690px;
  height: 194px;
  background-color: #4d0098;
  background-image: url("@/assets/six-box-bg-7.png");
  background-repeat: round;
  padding: 20px;
}
.big-box h1 {
  font-size: 16px;
}
.big-box p {
  font-size: 12px;
}
.outer {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.row {
  display: flex;
  flex: 1;
}
.world-bg img {
  width: 100%;
  margin-top: -80px;
  margin-bottom: -250px;
}
.proof-of-concept {
  background-image: url("@/assets/proof-of-concept-bg.png");
  background-repeat: round;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black !important;
  text-align: center;
  padding: 80px 80px 20px 80px;
}

.proof-of-concept p {
  color: #000 !important;
  font-weight: 100;
}
.proof-of-concept h1 {
  text-transform: uppercase;
  color: #bebebe;
}
.proof-of-concept h1 span {
  color: #3fff00;
}
.proof-of-concept h2 {
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #3fff00;
}
.proof-of-concept-item {
  padding: 32px 48px;
  border-radius: 8px;
  background-color: #f6f6f6;
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.15)
}
.proof-of-concept-item + hr {
  background-color: red;
  border: none;
}
.proof-of-concept-items {
  margin-top: 120px;
}
.footer {
  height: 80px;
  background-color: #fff;
}
.roadmap-container {
  overflow-x: auto;
  white-space: nowrap;
  cursor: grab;
  user-select: none;
  width: 1086px;
  height: auto;
  display: flex;
  align-items: baseline;
}
.roadmap-item {
  position: relative;
  display: inline-block;
  width: 300px;
  margin-right: 32px;
  background-color: rgba(37, 127, 0, 0.3);
  color: #fff;
  width: 471px;
  padding: 88px 32px 32px 32px;
  border-radius: 16px;
}
.grabbing {
  cursor: grabbing;
}
.roadmap h1 {
  text-align: center;
}
.roadmap-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.roadmap-box h2 {
  margin-bottom: 32px;
  color: #3fff00;
}
.roadmap-item h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
}
.roadmap-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}
.roadmap-item p {
  font-size: 14px;
  font-weight: 100;
  margin-top: 0px;
}
.roadmap-icon {
  position: absolute;
  top: -30px;
}
.faq {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  color: #000;
}
.faq-item h1 {
  margin-bottom: 15px;
}
.faq-item h2 {
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.faq-item {
  width: 1086px;
  display: flex;
  background-color: #f6f6f6;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 8px;
  text-align: start;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.15);
}
.faq-item p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.arrow {
  float: right;
  width: 20px;
  height: auto;
}
.display-arrow {
  display: none;
}
.partners-container h1 {
  color: #3fff00;
  margin-bottom: 48px;
}

.partners-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 1086px;
  margin-bottom: 48px;
}

.marquee {
  display: flex;
  align-items: center;
  position: relative;
}

.marquee-content {
  display: flex;
  animation: marquee 15s linear infinite;
}

.partner-logo {
  padding: 10px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fade {
  position: absolute;
  width: 100px; 
  height: 100%;
  z-index: 2;
}

.left-fade {
  left: 0;
  transform: rotateZ(180deg);
  background: linear-gradient(to left, #212121, transparent);
}

.right-fade {
  right: 0;
  background: linear-gradient(to left, #212121, transparent);
}

.white-line {
  position: absolute;
  background-color: #fff;
  height: 1px;
  opacity: 0.3;
}

.top-line {
  top: 0;
  width: 100%;
}

.bottom-line {
  bottom: 0;
  width: 100%;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%); 
  }
}

@media (max-width: 768px) {
  .buy-abx {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .buy-abx-left {
    margin-right: 0;
  }
  .buy-abx-right {
    width: 342px;
    height: auto;
    margin-right: 20px;
    margin-left: 20px;
  }
  .buy-abx-right h2 {
    font-size: 20px;
  }
  .buy-abx-payment-icons img {
    width: 294px;
  }
  .buy-abx-right h3 {
    font-size: 16px;
  }
  .buy-abx-solded {
    font-size: 20px;
  }
  .buy-abx-num {
    font-size: 16px;
  }
  .buy-abx-next-stage {
    font-size: 16px;
  }
  .buy-abx-payment-icons {
    height: 26px;
  }
  .buy-abx-slogan {
    width: auto;
    margin-top: 0px;
    padding-right: 0px;
    font-size: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .buy-abx-slogan h1 {
    width: 330px;
    margin-bottom: 24px;
    line-height: 38px;
    font-size: 24px;
  }
  .buy-abx-button {
    font-size: 20px;
  }

  .buy-abx-whitepaper {
    width: 342px;
    margin-bottom: 32px;
  }
  .buy-abx-whitepaper h2 {
    font-size: 20px;
  }
  .buy-abx-whitepaper p {
    font-size: 12px;
    margin-bottom: 25px;
  }
  .whitepaper-paragraph p {
    line-height: 21px;
    width: auto;
  }
  .how-does-work {
    padding-left: 25px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 0px;
  }
  .how-does-work p {
    margin-right: 15px;
    text-align: justify;
    font-size: 14px;
    line-height: 25px;
    font-weight: 100;
  }
  .how-does-work h1 {
    margin-top: 32px;
    font-size: 24px;
    text-align: center;
  }
  .meet-arbitrage {
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 320px;
    height: auto;
  }
  .meet-arbitrage p {
    font-size: 14px;
    margin-top: 0px;
    text-align: justify;
  }
  .meet-arbitrage h1 {
    font-size: 24px;
    margin-top: 0px;
  }
  .synergy-of-arbitrage-hft .row {
    display: flex;
    flex-direction: column;
  }
  .synergy-of-arbitrage-hft-header h1 {
    font-size: 32px;
    line-height: 45px;
  }
  .grid-box {
    margin-right: 0;
  }
  .big-box {
    width: auto;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
  .world-bg img {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .proof-of-concept {
    padding-top: 0px;
    background-color: #fff;
    margin-top: -50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .proof-of-concept-items {
    margin-top: 40px;
  }
  .proof-of-concept-item {
    padding: 20px;
  }
  .proof-of-concept h2 {
    font-size: 20px;
  }
  .proof-of-concept p {
    font-size: 16px;
  }
  .roadmap h1 {
    font-size: 24px;
  }
  .roadmap-icon {
    width: 32px;
  }
  .roadmap-box h2 {
    font-size: 20px;
    font-weight: 700;
  }
  .roadmap-box h3 {
    font-size: 16px;
    font-weight: 700;
  }
  .roadmap-box h4 {
    font-size: 14px;
    font-weight: 600;
  }
  .roadmap-box p {
    font-size: 12px;
    font-weight: 500;
  }
  .roadmap-container {
    width: 342px;
    height: auto;
  }
  .roadmap-item {
    width: 342px;
  }
  .faq-item {
    width: 342px;
    text-transform: lowercase;
  }
  .faq h1 {
    font-size: 24px;
    width: 342px;
    text-align: center;
  }
  .faq-item h2 {
    font-size: 16px;
  }

  .faq-item h2::first-letter,
  .faq-item p::first-letter {
    text-transform: capitalize;
  }
  .partners-container h1 {
  font-size: 24px;
}
.partners-container {
    width: 390px;
    height: auto;
}
}
</style>
