<template>
  <ArbiCoinBanner/>
  <ArbiCoinCountdown/>
</template>

<script>
import ArbiCoinBanner from './components/ArbiCoinBanner.vue'
import ArbiCoinCountdown from './components/ArbiCoinCountdown.vue'


export default {
  name: 'App',
  components: {
    ArbiCoinCountdown,
    ArbiCoinBanner
  }
}
</script>

<style>
html,body{
  margin: 0;
  overflow-x: hidden;
  background-color: #212121;
  
}
body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
