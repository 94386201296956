<template>
  <section class="countdown">
    <div class="countdown-times">
      <div>
        <h1>08</h1>
        <h2>Hours</h2>
      </div>
      <span>
        <img src="@/assets/countdown-dot.png" />
        <img src="@/assets/countdown-dot.png" />
      </span>
      <div>
        <h1>25</h1>
        <h2>Minute</h2>
      </div>
      <span>
        <img src="@/assets/countdown-dot.png" />
        <img src="@/assets/countdown-dot.png" />
      </span>
      <div>
        <h1>40</h1>
        <h2>Second</h2>
      </div>
    </div>
    <div class="countdown-info-boxes">
      <div>
        <p>Total Investment Pool</p>
        <h2>XXX</h2>
      </div>
      <div>
        <p>Total Bonus Reward To Be<br />Distributed During The Period</p>
        <h2>XXX</h2>
      </div>
      <div>
        <p>Total Bonus Reward Distributed In All-time</p>
        <h2>XXX</h2>
      </div>
    </div>
    <div></div>
  </section>
</template>
<script>
export default {
  name: "ArbiCoinCountdown",
};
</script>
<style>
body {
  color: #fff;
}
.countdown {
  margin: 80px 140px 0 140px;
  box-sizing: border-box;
}
.countdown-times {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-times div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 144px;
  height: 111px;
  background-color: rgba(37, 127, 0, 0.3);
  border-radius: 8px;
  margin-right: 47px;
  margin-left: 47px;
}
.countdown-times img {
  margin-right: 1px;
  margin-left: 1px;
}
.countdown-times h1 {
  font-size: 48px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}
.countdown-times h2 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 500;
}
.countdown-info-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}
.countdown-info-boxes div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.countdown-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-info-boxes p {
  font-size: 16px;
  background-color: #1a97ff;
  border-radius: 8px;
  width: 266px;
  height: 72px;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 100;
}
.countdown-info-boxes h2 {
  font-size: 24px;
  font-weight: 100;
}
@media (max-width: 768px) {
  .countdown-times div {
    width: 78px;
    height: 61px;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .countdown-times span {
    display: flex;
  }
  .countdown-times img {
    width: 6px;
    height: 6px;
  }
  .countdown-info-boxes {
    flex-direction: column;
  }
  .countdown-info-boxes p {
    width: 336px;
    height: 64px;
    margin-bottom: 0px;
  }
  .countdown-times h1 {
    font-size: 26px;
  }
  .countdown-times h2 {
    font-size: 10px;
  }
  .countdown-info-boxes h2 {
    font-size: 20px;
  }
}
</style>
